<template>
  <!-- dense view -->
  <div v-if="dense">
    <v-switch v-model="switchState"
              inset hide-details
              :disabled="!device.gatewayOnline || !allowValueChange"
              :loading="loading"
              class="mt-1 ml-4"
              @change="updateProp"/>
    <div class="mt-3" v-if="switchState"
          v-html="$t('device-list.state.on')"/>
    <div class="mt-3" v-else
          v-html="$t('device-list.state.off')"/>
  </div>

  <!-- normal size view -->
  <div v-else>
    <v-switch v-model="switchState"
              inset
              class="pb-1"
              :disabled="!device.gatewayOnline || !allowValueChange"
              :loading="loading"
              @change="updateProp"/>
  </div>
</template>

<script>

export default {
  name: 'SwitchInput',

  components: {},

  props: {
    device: Object,
    dense: Boolean
  },

  data: function () {
    return {
      switchState: this.device?.state,
      allowValueChange: true,
      cooldown: 3000,
      changeTimeout: null,
      loading: false
    }
  },

  methods: {
    updateProp() {
      this.loading = true

      let self = this

      this.allowValueChange = false
      if (this.changeTimeout) {
        clearTimeout(this.changeTimeout)
      }
      this.changeTimeout = setTimeout(() => {
        self.allowValueChange = true
      }, self.cooldown)

      this.$rhRequest.sendPost({
            endpoint: 'devices/update-property',
            data: {
              deviceId: this.device.id,
              state: this.switchState
            }
          },
          function () {
            self.loading = false
          },
          function (error) {
            console.error(error)
            if (error.code !== 'ECONNABORTED') {
              this.$root.bisatoast.error({message: this.$t('app.generic-error')})
            }
            self.loading = false
          })
    }
  },

  watch: {
    device: function () {
      if (this.allowValueChange) {
        this.switchState = this.device?.state
      }
    }
  }
}

</script>
