<template>
  <v-slider v-model="value"
            :disabled="!device.gatewayOnline"
            class="non-flip pt-10 pb-1 px-1"
            hide-details
            :min="actuator?.minValue"
            :max="actuator?.maxValue"
            thumb-label="always"
            :append-icon="increaseIcon"
            :prepend-icon="decreaseIcon"
            @click:append="increase"
            @click:prepend="decrease"
            @change="updateProp">
    <template v-slot:thumb-label="{ value }">
      <span class="font-weight-bold">{{ value }}</span>
      <sup>{{ actuator?.unit }}</sup>
    </template>
  </v-slider>
</template>

<script>

import deviceProperties from "@/config/deviceProperties.json";

export default {
  name: 'SliderInput',

  components: {},

  props: {
    device: Object,
    actuator: Object,
    dense: Boolean
  },

  data: function () {
    return {
      value: this.device.value,
      allowValueChange: true,
      cooldown: 3000,
      changeTimeout: null,
      loading: false
    }
  },

  computed: {
    increaseIcon() {
      return deviceProperties[this.actuator?.name]?.increaseIcon
    },

    decreaseIcon() {
      return deviceProperties[this.actuator?.name]?.decreaseIcon
    },
  },

  methods: {
    increase() {
      this.value++
      this.updateProp()
    },

    decrease() {
      this.value--
      this.updateProp()
    },

    updateProp() {
      this.loading = true
      this.allowValueChange = false
      if (this.changeTimeout) {
        clearTimeout(this.changeTimeout)
      }
      this.changeTimeout = setTimeout(() => {
        this.allowValueChange = true
      }, this.cooldown)

      this.$rhRequest.sendPost({
        endpoint: 'devices/update-property',
        data: {
          deviceId: this.device.id,
          value: this.value
        }
      }, () => {
        this.loading = false
      }, (error) => {
        console.error(error)
        if (error.code !== 'ECONNABORTED') {
          this.$root.bisatoast.error({message: this.$t('app.generic-error')})
        }
        this.loading = false
      })
    }
  },

  watch: {
    device: function () {
      if (this.allowValueChange) {
        this.value = this.device?.value
      }
    }
  }
}

</script>
