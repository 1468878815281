<!--
  Template for a marketplace or discover (Habilio) offering
-->
<template>
  <v-hover v-slot="{ hover }">
    <v-card class="offer-card"
            :link="content.linkAddress !== null"
            @click="openDetails">
      <v-img :aspect-ratio="7/3" class="overflow-hidden"
             height="100%"
             :src="getImgSrc()"
             :lazy-src="getImgSrc()">
        <v-expand-transition>
          <div v-if="hover && !extended"
               class="d-flex transition-fast-in-fast-out primary v-card--reveal full-height tileBackground">
            <span class="pa-5" v-text="content.body"/>
            <v-icon v-if="hover && (content.discoverPageId || content.displayOptions?.hasDetailPage)" color="white"
                    class="link-icon">
              chevron_right
            </v-icon>
            <v-icon v-else-if="hover && content.linkAddress" color="white"
                    class="link-icon">
              north_east
            </v-icon>
          </div>
        </v-expand-transition>
        <div class="background-gradient">
          <v-card-title class="offer-title white--text font-weight-bold font-size-04 text-break"
                        v-if="!hover || extended">
            <v-icon left color="white"
                    v-if="content.iconKey?.length > 0">
              {{ content.iconKey }}
            </v-icon>
            <span v-text="content.title"/>
          </v-card-title>
        </div>
      </v-img>
      <v-card-text v-if="extended" class="offer-text">
        {{ content.body }}
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
import config from "@/config/config.app.json";
import inAppBrowser from "@/scripts/inAppBrowser";

export default {
  name: "OfferCard",

  props: {
    content: Object,
    // also shows the short description
    extended: Boolean
  },

  methods: {
    openDetails () {
      if(this.content.linkAddress !== null) {
        this.$econda.sendDiscover(this.content.title)
      }
      if (this.content.discoverPageId) {
        this.$emit('click')
      } else if (this.content.displayOptions?.hasDetailPage) {
        this.$root.bisadialog.toggle('contentDetailPage', true, this.content)
      } else  if (this.content.linkAddress) {
        this.openInapp(this.content.linkAddress)
      }
    },

    /**
     * returns the v-img source for an image id or img src
     *
     * @returns {string}
     */
    getImgSrc() {
      if (this.content.imgSrc) {
        return this.content.imgSrc
      }
      return config.portalApi + 'content-service/get-image?id=' + this.content.imageId
    },

    /**
     * opens an url in either InAppBrowser (mobile device) or target="_blank"
     * @param url
     */
    openInapp(url) {
      inAppBrowser.openUrl(url)
    }
  }
}
</script>

<style lang="scss">
.full-height {
  height: 100%;
}

.link-icon {
  position: absolute !important;
  bottom: 5px;
  right: 5px;
}

.offer-text {
  height: 152px;
}
</style>
